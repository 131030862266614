import Link from "next/link";
import { useTranslation } from "next-i18next";
import { arrayOf, bool, shape, string } from "prop-types";
import React from "react";

import ImageComponent from "~components/ImageComponent";
import NewIcon from "~components/NewIcon";
import LinkIcon from "~public/icons/link.svg";
import { ImageType, SocialMediaChannelTypes } from "~types";
import classnames from "~utils/classnames";

import classes from "./PersonCard.module.scss";

const PersonCard = ({
  id,
  avatar,
  name,
  firstName,
  description,
  jobTitle,
  email,
  contactPage,
  phoneNumber,
  socials,
  vertical,
}) => {
  const { t } = useTranslation();

  const imageSizes = vertical
    ? { width: 296, height: 243 }
    : { width: 389, height: 184 };

  return (
    <div
      key={id}
      className={classnames(classes.card, vertical && classes.vertical)}
    >
      <div className={classes.image}>
        <ImageComponent
          {...avatar[0]}
          {...imageSizes}
          hideAuthor
          layout="fill"
        />
      </div>
      <div className={classes.cardContent}>
        <div className={classes.name}>{name}</div>
        <div className={classes.description}>{description || jobTitle}</div>
        <div className={classes.socialsTitle}>
          {firstName
            ? `${t("contactPerson")} ${firstName}`
            : t("contactPerson")}
        </div>
        {email && (
          <Link href={`mailto:${email}`} className={classes.contact}>
            <NewIcon type="email" />
            {email}
          </Link>
        )}
        {contactPage.length > 0 && (
          <Link href={contactPage[0].slug} className={classes.contact}>
            <LinkIcon />
            {t("contactUs")}
          </Link>
        )}
        {phoneNumber && (
          <Link href={`tel:${phoneNumber}`} className={classes.contact}>
            <NewIcon type="phone" />
            {phoneNumber}
          </Link>
        )}

        {socials && (
          <div className={classes.socials}>
            {socials.map((social) => (
              <Link
                key={social.id}
                href={social.url}
                className={classes.social}
                target="_blank"
                title={social.platform}
              >
                <NewIcon type={social.platform} focusable={false} />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const PersonType = {
  id: string,
  avatar: arrayOf(shape(ImageType)),
  name: string,
  firstName: string,
  jobTitle: string,
  email: string,
  contactPage: arrayOf(
    shape({
      slug: string.isRequired,
    }),
  ),
  phoneNumber: string,
  description: string,
  socials: arrayOf(shape(SocialMediaChannelTypes)),
  vertical: bool,
};

PersonCard.propTypes = PersonType;

export default PersonCard;
