import Link from "next/link";
import { oneOf, shape, string } from "prop-types";
import React from "react";

import ImageComponent from "~components/ImageComponent";
import ImagePlaceholder from "~components/ImagePlaceholder";
import ArrowRight from "~public/icons/arrow-right.svg";
import { ImageType, PreprDataType } from "~types";
import { toValidUrl } from "~utils/routing";

import classes from "./LinkCard.module.scss";

export const cardImageSizes = {
  small: {
    width: 100,
    height: 100,
  },
  medium: {
    width: 384,
    height: 218,
  },
  large: {
    width: 280,
    height: 280,
  },
  "x-large": {
    width: 440,
    height: 440,
  },
};

const LinkCard = ({
  size = "medium",
  title,
  slug,
  image,
  top,
  bottom,
  imageSource = "feedfactory",
  preprData,
}) => {
  if (!slug) {
    console.warn(`LinkCard: slug is required, no slug provided for ${title}`);
    return null;
  }
  return (
    <Link
      href={toValidUrl(slug || "/")}
      className={classes[`card_${size}`]}
      // eslint-disable-next-line
      prepr-personalisation={preprData?.personalizationId}
      // eslint-disable-next-line
      prepr-segment={preprData?.segmentIds}
    >
      <div className={classes.image}>
        {image ? (
          <ImageComponent
            {...image}
            alt={image.alt || title}
            width={cardImageSizes[size].width}
            height={cardImageSizes[size].height}
            layout={imageSource === "prepr" ? "responsive" : "fill"}
            hideAuthor
          />
        ) : (
          <ImagePlaceholder height={cardImageSizes[size].height} />
        )}
      </div>
      <div className={classes.card_body}>
        {top && <div className={classes.card_top}>{top}</div>}
        <div className={classes.card_title}>
          <h3>{title}</h3>
          {size !== "small" && (
            <div className={classes.card_title_icon}>
              <ArrowRight />
            </div>
          )}
        </div>
        {bottom && <div className={classes.card_bottom}>{bottom}</div>}
      </div>
    </Link>
  );
};

LinkCard.propTypes = {
  size: oneOf(Object.keys(cardImageSizes)),
  title: string.isRequired,
  slug: string.isRequired,
  image: shape(ImageType).isRequired,
  top: string,
  bottom: string,
  imageSource: oneOf(["prepr", "feedfactory"]),
  preprData: shape(PreprDataType),
};

export default LinkCard;
