import { useRouter } from "next/router";
import { instanceOf } from "prop-types";

import formatDate from "~utils/formatDate";

import classes from "../EventDate.module.scss";

const DateTag = ({ eventDate }) => {
  const { locale } = useRouter();
  return (
    <div className={classes.eventDatePart}>
      <div className={classes.eventDay}>
        {formatDate(eventDate, locale, "dd")}
      </div>
      <div className={classes.eventMonthYear}>
        {formatDate(eventDate, locale, "MMM yy").replace(" ", " '")}
      </div>
    </div>
  );
};

DateTag.propTypes = {
  eventDate: instanceOf(Date),
};

export default DateTag;
