import { bool, node, oneOf, string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./Chip.module.scss";

const Chip = ({
  active = false,
  children,
  className,
  spaced = false,
  color,
  variant = "primary",
}) => {
  const chipClass = classnames(
    classes[`chip${active ? "_active" : ""}`],
    color && classes[`chip_${color}`],
    spaced && classes.chip_spaced,
    classes[variant],
    className,
  );

  return <div className={chipClass}>{children}</div>;
};

Chip.propTypes = {
  active: bool,
  children: node.isRequired,
  className: string,
  spaced: bool,
  color: oneOf(["grey", "black", "primary", "secondary"]),
  variant: oneOf(["default", "theme"]),
};

export default Chip;
