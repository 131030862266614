import { node, number, oneOf, oneOfType, shape, string } from "prop-types";

import ImageComponent from "~components/ImageComponent";
import ImagePlaceholder from "~components/ImagePlaceholder";
import { ImageType } from "~types";

import classes from "./BaseImage.module.scss";

const BaseImage = ({
  title,
  image,
  imageSize = { width: 330, height: 184 },
  layout = undefined,
  topRightComponent,
  topLeftComponent,
  bottomLeftComponent,
}) => {
  return (
    <div className={classes.image}>
      {image ? (
        <ImageComponent
          {...image}
          alt={image.alt || title}
          width={imageSize.width}
          height={imageSize.height}
          layout={layout}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          hideAuthor
        />
      ) : (
        // Todo: Create placeholder image. IAMS-405
        <ImagePlaceholder height={imageSize.height} />
      )}
      {topRightComponent && (
        <div className={classes.topRightComponent}>{topRightComponent}</div>
      )}
      {topLeftComponent && (
        <div className={classes.topLeftComponent}>{topLeftComponent}</div>
      )}
      {bottomLeftComponent && (
        <div className={classes.bottomLeftComponent}>{bottomLeftComponent}</div>
      )}
    </div>
  );
};

BaseImage.propTypes = {
  title: string,
  image: shape(ImageType).isRequired,
  imageSize: shape({
    width: number,
    height: number,
  }),
  layout: oneOf(["fill", "responsive", undefined]),
  topRightComponent: oneOfType([string, node]),
  topLeftComponent: oneOfType([string, node]),
  bottomLeftComponent: oneOfType([string, node]),
};

export default BaseImage;
